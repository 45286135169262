<template>
  <img :alt="alt" :src="computedSrc" />
</template>

<script>
export default {
  name: "Imgix",
  props: ["alt", "src", "w", "h"],
  computed: {
    computedSrc: function() {
      return this.src
    }
  }
}
</script>
