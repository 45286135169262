import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Meta from "vue-meta"
import VueAnalytics from "vue-analytics"

const requireComponent = require.context(
  "./components/elements",
  // Do not look in subdirectories
  false,
  // Only include .vue files
  /[\w-]+\.vue$/
)

requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  // Get name of component
  const componentName =
    // Strip the leading `./` and extension from the filename
    fileName.replace(/^\.\/(.*)\.\w+$/, "$1")

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})

Vue.config.productionTip = false
const isProd = process.env.NODE_ENV === "production"

Vue.use(Meta)
Vue.use(VueAnalytics, {
  id: "UA-15520446-1",
  router,
  autoTracking: {
    pageviewOnLoad: false
  },
  debug: {
    enabled: !isProd,
    sendHitTask: isProd
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
