<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
export default {
  name: "App",
  metaInfo: {
    title: "GD Hall",
    titleTemplate: "%s"
  }
}
</script>

<style>
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";
@import url("https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@400;700&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

*:focus {
  outline: none !important;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
}

.slicknav_menu {
  display: none;
}

html,
body {
  padding: 0;
  margin: 0;
}

body {
  box-sizing: border-box;
  font-size: 1.5rem;
  color: #666;
}

html {
  font-size: 62.5%;
}

body,
input,
select,
textarea {
  font-family: "Frank Ruhl Libre", sans-serif;
}

.inner {
  display: table;
  width: 94%;
  max-width: 1400px;
  margin: auto;
}

.half {
  width: 47%;
}

.half:first-child {
  margin-right: 6%;
}

.form .half {
  width: 49.5%;
}

.form .half:first-child {
  margin-right: 1%;
}

.col {
  float: left;
}

.content {
  padding: 5rem 0;
  font-size: 1.75rem;
  line-height: 1.6;
}

.content h1,
.content h2,
.content h3 {
  font-family: "Frank Ruhl Libre", Georgia, "Times New Roman", Times, serif;
  color: #0060a9;
  font-size: 2.4rem;
  display: inline-block;
  padding-bottom: 1rem;
}

.content h2 {
  font-size: 2rem;
  border-bottom: 0;
  padding-bottom: 0;
  display: block;
}

.content h3 {
  font-size: 1.7rem;
  border-bottom: 0;
  padding-bottom: 0;
  color: #e2ba32;
  display: block;
}

p {
  margin-bottom: 2rem;
}

.content ul,
.content ol {
  padding-left: 2rem;
  padding-bottom: 2rem;
}

.hide {
  display: none !important;
}

.gallery {
  line-height: 0;
  padding: 1rem 0;
  position: relative;
}

.gallery img {
  transition: all 0.2s;
  border-radius: 0.7rem;
}

.gallery a {
  display: none;
}

.gallery a:nth-child(1) {
  display: block;
}

.full .gallery a {
  display: inline-block;
}

.full .gallery img {
  width: 20%;
}

.gallery a.full img {
  width: 100%;
}

.gallery img:hover {
  filter: brightness(1.1);
  transform: scale(1.05);
}

.gallery a.thumb {
  display: block;
  width: 20%;
  float: left;
  margin-bottom: 2rem;
}

.gallery a.thumb img {
  width: 100%;
}

.gallery a:hover {
  z-index: 1;
}

.gallery a.thumb span {
  display: block;
  padding: 2rem 1rem;
}

.view {
  position: absolute;
  bottom: 3rem;
  right: 3rem;
  color: white;
  text-align: center;
  display: block;
  line-height: 1;
  font-family: "Frank Ruhl Libre", Georgia, "Times New Roman", Times, serif;
  font-size: 2rem;
  z-index: 2;
}

.view span {
  display: inline-block;
  background: #e2ba32;
  border-radius: 0.7rem;
  padding: 2rem 4rem;
}

.view i {
  margin-right: 0.5rem;
}

.obit-thumb {
  display: block;
  float: left;
  width: 32%;
  margin: 3rem 0;
  line-height: 0;
}

.obit-thumb:hover > .obit-img {
  filter: brightness(1.1);
  transform: scale(1.05);
}

.obit-thumb:nth-child(2) {
  margin: 3rem 2%;
}

.obit-img {
  float: left;
  width: 50%;
  height: 16rem;
  background-image: url(assets/candles.jpg);
  background-size: cover;
  display: block;
  transition: all 0.2s;
}

.obit-info {
  float: left;
  width: 45%;
  padding-left: 5%;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 0;
  text-align: left;
  line-height: 1.5;
  min-height: 16rem;
  background: #ffffff16;
}

.obit-info h4 {
  font-family: "Frank Ruhl Libre", Georgia, "Times New Roman", Times, serif;
  font-size: 2rem;
  max-height: 6rem;
  overflow: hidden;
  line-height: 1.1;
  margin-bottom: 1rem;
}

.obit-info h5 {
  font-size: 1.2rem;
  opacity: 0.6;
}

.obit-info a {
  display: block;
  margin-top: 1rem;
}

.content p a,
.content ul a,
.content ol a {
  border-bottom: 3px solid #e2ba32;
}
.content ul.help a {
  border-bottom: 0;
}

.grecaptcha-badge {
  position: static !important;
  opacity: 0;
  margin-bottom: -5rem;
}

.fees-list a {
  display: block;
  background: #166eb0;
  margin-top: 2rem;
  border-radius: 0.7rem;
  color: white;
  padding: 1.5rem 2rem;
}

.fees-list i {
  color: #e2ba32;
  margin-right: 1rem;
}

@media screen and (max-width: 1300px) {
  html {
    font-size: 50%;
  }
}

@media screen and (max-width: 950px) {
  .slicknav_menu {
    display: block;
  }

  .half,
  .form .half {
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 3rem;
  }

  .content {
    padding: 2.5rem 0;
  }

  h1,
  .content h1 {
    font-size: 2rem;
  }
  .gallery a.thumb {
    width: 33.3%;
  }

  .col-2 {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .gallery a.thumb,
  .full .gallery a img,
  .gallery a img {
    width: 50%;
  }

  .mfp-arrow {
    display: none !important;
  }
}

@media screen and (max-width: 450px) {
  .gallery a.thumb {
    width: 100%;
  }
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

/* Slicknav */
.slicknav_btn {
  position: relative;
  display: block;
  vertical-align: middle;
  float: right;
  padding: 0.438em 2rem 0.438em 0.625em;
  line-height: 1.125em;
  cursor: pointer;
}

.slicknav_menu .slicknav_menutxt {
  display: block;
  line-height: 1.188em;
  float: left;
}

.slicknav_menu .slicknav_icon {
  float: left;
  margin: 0.188em 0 0 0.438em;
}

.slicknav_menu .slicknav_no-text {
  margin: 0;
}

.slicknav_menu .slicknav_icon-bar {
  display: block;
  width: 1.125em;
  height: 0.125em;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar {
  margin-top: 0.188em;
}

.slicknav_nav {
  clear: both;
}

.slicknav_nav ul,
.slicknav_nav li {
  display: block;
}

.slicknav_nav .slicknav_arrow {
  font-size: 1rem;
  margin: 0 0 0 0.4em;
  top: -0.25rem;
  position: relative;
}

.slicknav_nav .slicknav_item {
  display: block;
  cursor: pointer;
}

.slicknav_nav a {
  display: block;
}

.slicknav_nav .slicknav_item a {
  display: inline;
}

.slicknav_menu:before,
.slicknav_menu:after {
  content: " ";
  display: table;
}

.slicknav_menu:after {
  clear: both;
}

/* IE6/7 support */

.slicknav_menu {
  *zoom: 1;
}

/* User Default Style Change the following styles to modify the appearance of the menu.*/

.slicknav_menu {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  z-index: 1000;
  background-color: #e2ba32;
}

/* Button */

.slicknav_btn {
  margin: 0 0 0;
  text-decoration: none;
}

/* Button Text */

.slicknav_menu .slicknav_menutxt {
  color: #fff;
  font-weight: bold;
}

/* Button Lines */

.slicknav_menu .slicknav_icon-bar {
  background-color: #0060a9;
  font-size: 2rem;
}

.slicknav_menu {
  font-size: 2rem;
}

.slicknav_nav {
  color: #fff;
  margin: 0;
  padding: 0;
}

.slicknav_nav,
.slicknav_nav ul {
  list-style: none;
  overflow: hidden;
}

.slicknav_nav ul {
  padding: 0;
  margin: 0 0 0 0;
}

.slicknav_nav a {
  padding: 5px 3%;
  margin: 0 0;
  text-decoration: none;
  color: #fff;
}

.slicknav_nav > li > a,
.slicknav_nav > li > a > a {
  color: #fff;
}

.slicknav_nav .slicknav_item a {
  padding: 0;
  margin: 0;
}

.slicknav_nav .slicknav_txtnode {
  margin-left: 15px;
}

.slicknav_nav i {
  display: none;
}

.slicknav_menu ul ul {
  color: white;
}

.slicknav_menu ul ul li {
  padding-left: 20px;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}
.mfp-preloader a {
  color: #ccc;
}
.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}
.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}
.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}
.mfp-arrow:active {
  margin-top: -54px;
}
.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}
.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}
.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}
.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}
.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}
.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f;
}

.mfp-arrow-right {
  right: 0;
}
.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px;
}
.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}
.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}
.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}
.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}
.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
</style>
