import Vue from "vue"
import Vuex from "vuex"
import api from "@/services/api"
import formatdate from "@/utils/formatdate"
import datetimeutil from "date-and-time"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ready: false,
    site_id: "gdhall",
    s3: "https://f003.backblazeb2.com/file/gdhallmedia/",
    settings: null,
    main_pages: null,
    obits: null,
    latest_obits: null,
    featured_pages: null,
    privacy: null,
    team: null,

    homeID: "qqZxTwqm5",
    aboutID: "16tKRZDRc",
    contactID: "rQjtJ0aSc",
    newsID: "deVQchu4C",
    faqsID: "TMniaSlfc",
    keepsakesID: "cWPJJ986d",
    obitID: "niBmKeAiw",
    privacyID: "K0_7Xtb0r",
    teamID: "qx88cNTZe"
  },
  mutations: {
    setSettings(state, settings) {
      state.settings = settings
    },
    setMainPages(state, main_pages) {
      state.main_pages = main_pages
    },
    setObits(state, obits) {
      state.obits = obits
    },
    setLatestObits(state, latest_obits) {
      state.latest_obits = latest_obits
    },
    setFeaturedPages(state, featured_pages) {
      state.featured_pages = featured_pages
    },
    setPrivacyPage(state, privacy) {
      state.privacy = privacy
    },
    setTeamPage(state, team) {
      state.team = team
    }
  },
  actions: {
    getSettings({ commit, state }) {
      return new Promise(resolve => {
        if (!state.settings)
          api.getPageByItemID("SETTINGS").then(settings => {
            commit("setSettings", settings)
            resolve()
          })
        else resolve()
      })
    },
    getMainPages({ commit, state }) {
      return new Promise(resolve => {
        if (!state.main_pages)
          api.getPagesPOST("", "page.main").then(main_pages => {
            // ??? check this
            commit("setMainPages", main_pages)
            resolve()

            var promises = []

            main_pages.forEach(page => {
              promises.push(api.getPagesPOST("", "page.sub=@" + page.ItemID).then(subs => Vue.set(page, "subs", subs)))
            })

            Promise.all(promises).then(() => {
              commit("setMainPages", main_pages)

              Vue.nextTick().then(() => {
                if (!window.$(".slicknav_menu").length) window.$(".sf-menu").slicknav()
              })
            })
          })
        else resolve()
      })
    },

    getObits({ commit, state }) {
      return new Promise(resolve => {
        if (!state.obits)
          api.getPages("page.obituaries", "ItemType,image,date_of_birth,date_of_death,date_of_funeral", "", "", "desc").then(obits => {
            obits.forEach(obit => {
              //console.log(obit.date_of_funeral)
              if (obit.date_of_funeral) obit.order = datetimeutil.format(datetimeutil.parse(obit.date_of_funeral, "DD/MM/YYYY"), "YYYY-MM-DD")
            })
            obits.sort((a, b) => (a.order < b.order ? 1 : b.order < a.order ? -1 : 0))
            commit("setLatestObits", JSON.parse(JSON.stringify(obits.slice(0, 3))))
            commit("setObits", obits)
            resolve()
          })
        else resolve()
      })
    },
    getFeaturedPages({ commit, state }) {
      return new Promise(resolve => {
        if (!state.featured_pages) {
          var promises = []
          promises.push(api.getPagesPOST("", "page.main", "#featured=:true", { "#featured": "featured" }))
          promises.push(api.getPagesPOST("", "page.sub", "#featured=:true", { "#featured": "featured" }))
          promises.push(api.getPagesPOST("", "page.articles", "#featured=:true", { "#featured": "featured" }))

          Promise.all(promises).then(values => {
            var pages = values[0].concat(values[1])
            pages = pages.concat(values[2])
            commit("setFeaturedPages", pages)
            resolve()
          })
        } else resolve()
      })
    },
    getPrivacyPage({ commit, state }) {
      return new Promise(resolve => {
        if (!state.obits)
          api.getPageByItemID(state.privacyID, "name,url").then(privacy => {
            commit("setPrivacyPage", privacy)
            resolve()
          })
        else resolve()
      })
    }
  }
})
